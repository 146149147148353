import { reactive } from 'vue';

export const notifications = reactive({
	
	ttl: 3000,
    updatingList: [],
    observers: [],

	registerObserver(observer) {
		this.observers.push(observer);
	},
	hasMessages() {
		return this.updatingList.filter(x => !x.status).length > 0;
	},
	push(id, message) {
		this.updatingList.push({id: id, status: null, message: message});
		this.observers.forEach((observer) => observer.$forceUpdate());
	},
	remove(id) {
		this.updatingList = this.updatingList.filter(line => line.id != id);
		this.observers.forEach((observer) => observer.$forceUpdate());
	},
	toast(id, message, status) {
		this.updatingList.push({id: id, status: status, message: message});
		this.observers.forEach((observer) => observer.$forceUpdate());
		
		setTimeout(() => {
			this.remove(id);
	    }, this.ttl);
	},
	update(id, statusCode) {
		var status = statusCode == 200 ? 'ok' : 'error';
		this.updatingList.forEach(function(line) {if(line.id == id) { line.status = status; }});
		this.observers.forEach((observer) => observer.$forceUpdate());

		setTimeout(() => {
			this.remove(id);
	    }, this.ttl);
	}
		
});

export const alerts = reactive({
	
    alertsList: [],
    observers: [],

	registerObserver(observer) {
		this.observers.push(observer);
	},
	hasAlerts() {
		return this.alertsList.length > 0;
	},
	numberOfAlerts() {
		return this.alertsList.length;
	},
	push(alert) {
		this.alertsList.push(alert);
		this.observers.forEach((observer) => observer.$forceUpdate());
	},
	update(newAlertsList) {
		this.alertsList = newAlertsList;
		this.observers.forEach((observer) => observer.$forceUpdate());
	}
});
