// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
require("@rails/ujs").start()
//require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
//require("./Chart.js")

import jQuery from 'jquery'
window.$ = jQuery
window.jQuery = jQuery

import 'bootstrap'

require("./sorttable.js")
import {block_screen, unblock_screen} from './functions'
import {notifications, alerts} from './modules/global_vars.js'
import { reactive } from 'vue';

window.notifications = notifications;
window.alerts = alerts;

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

function registerServiceWorker() {
	if (!('serviceWorker' in navigator)) {
	  // Service Worker isn't supported on this browser, disable or hide UI.
	  return;
	}

	if (!('PushManager' in window)) {
	  // Push isn't supported on this browser, disable or hide UI.
	  return;
	}

	return navigator.serviceWorker.register('/service-worker.js')
	// return navigator.serviceWorker.register('/pwa-sw.js')
	.then(function(registration) {
    	console.log('Service worker successfully registered.');
    	return registration;
  	})
  	.catch(function(err) {
    	console.error('Unable to register service worker.', err);
  	});
}


// //import ProgressiveWebApp from 'pwa-rails';
// //document.addEventListener('turbolinks:load', () => {
// document.addEventListener('DOMContentLoaded', () => {
// //	const progressiveWebApp = new ProgressiveWebApp();
// //	const progressiveWebApp = new ProgressiveWebApp('pwa-sw.js');
// 	//console.log("Por qué no funciona?");
// 	//registerServiceWorker();
// 	//unblock_screen();
// });
		
document.addEventListener('readystatechange', (event) => {
	//alert(document.readyState);
	if(document.readyState == "complete") {
		registerServiceWorker();
		unblock_screen();
	}
});

// window.addEventListener('pageshow', function(event) {
// 	alert(document.readyState);
// 	if (event.persisted) {
// 	  window.location.reload();
// 	}
//   });
  

window.addEventListener('beforeunload', (event) => {
//	const progressiveWebApp = new ProgressiveWebApp();
//	const progressiveWebApp = new ProgressiveWebApp('pwa-sw.js');
	// Aquí la idea es que no te avise si hay acciones sin terminar antes de salir de la página
//	console.log("molaba...");
	if(notifications && notifications.hasMessages()) {
		event.returnValue = "Quedan resultados por guardar";
	} else {
		block_screen();
	}
});
